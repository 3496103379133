import '@/shared/assets/css/fonts.css'
import '@/shared/assets/css/tailwind.css'

import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { sentryPlugin } from '@/shared/plugins/sentry'
import { api } from '@/shared/plugins/api'
import { modalPlugin } from '@/shared/plugins/modal'

import App from './App.vue'
import router from './router'

const app = createApp(App)

//@ts-ignore
app.use(createPinia())
//@ts-ignore
app.use(router)
sentryPlugin(app, router)
app.use(api.apiPlugin)
app.use(modalPlugin)

app.mount('#app')
