import { createRouter, createWebHistory } from 'vue-router'
import { ROUTE_NAV } from '@/shared/config'
const CabHeader = () => import('@/widgets/cab-header/ui/CabHeader.vue')

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: ROUTE_NAV.index,
      name: 'index',
      component: () => import('@/pages/IndexPage.vue')
    },
    {
      path: ROUTE_NAV.error,
      name: 'error',
      component: () => import('@/pages/ErrorPage.vue')
    },
    {
      path: '/cab',
      name: 'cab',
      component: () => import('@/pages/cab/CabPage.vue'),
      redirect: () => ({ path: ROUTE_NAV.cabBundles }),
      children: [
        {
          path: ROUTE_NAV.cabBundles,
          name: 'bundles',
          components: {
            header: CabHeader,
            default: () => import('@/pages/cab/BundlesPage.vue')
          }
        },
        {
          path: ROUTE_NAV.cabHistory,
          name: 'history',
          components: {
            header: CabHeader,
            default: () => import('@/pages/cab/HistoryPage.vue')
          }
        },
        {
          path: ROUTE_NAV.cabRefund + '/:id/',
          name: 'refund',
          components: {
            header: CabHeader,
            default: () => import('@/pages/cab/RefundPage.vue')
          }
        },
        {
          path: ROUTE_NAV.cabReport + '/:id/',
          name: 'report',
          components: {
            header: CabHeader,
            default: () => import('@/pages/cab/ReportPage.vue')
          }
        },
        {
          path: ROUTE_NAV.cabPayment,
          name: 'payment',
          components: {
            header: CabHeader,
            default: () => import('@/pages/cab/PaymentPage.vue')
          }
        },
        {
          path: ROUTE_NAV.cabPayment3ds,
          name: 'payment-3ds',
          components: {
            default: () => import('@/pages/cab/Payment3dsPage.vue')
          }
        },
        {
          path: ROUTE_NAV.cabPaymentError,
          name: 'payment-error',
          components: {
            header: CabHeader,
            default: () => import('@/pages/cab/PaymentErrorPage.vue')
          }
        },
        {
          path: ROUTE_NAV.cabPaymentSuccess,
          name: 'payment-success',
          components: {
            header: CabHeader,
            default: () => import('@/pages/cab/PaymentSuccessPage.vue')
          }
        }
      ]
    }
  ]
})

export default router
