import type { InjectionKey } from 'vue'
import type { IModalPlugin } from './modal.types'

/**
 * @description A constant representing the InjectionKey for the IModalPlugin
 * interface. This constant is used in the provide/inject mechanism within
 * business logic to handle modals.
 * @type {InjectionKey<IModalPlugin>}
 * @example
 * // In a component or other logic that needs to use the modal plugin
 * import { ModalKey } from '@/shared/plugins/modal'
 * const $modal = inject(ModalKey)
 */
export const ModalKey: InjectionKey<IModalPlugin> = Symbol('Modal')
