export const env = Object.freeze({
  HOST_NAME: 'aveola.app',
  PROJECT_NAME: import.meta.env.PKG.PROJECT_NAME,
  VERSION: import.meta.env.PKG.VERSION || '0.0.0',
  MODE: import.meta.env.MODE,

  AUTH_URL: import.meta.env.VITE_AUTH_URL,
  APP_URL: import.meta.env.VITE_APP_URL,
  TRACK_URL: import.meta.env.VITE_TRACK_URL,

  VERSION_BACKEND: import.meta.env.VITE_VERSION_BACKEND || '0',
  APPLICATION: import.meta.env.VITE_APPLICATION,

  SENTRY_DNS: import.meta.env.VITE_SENTRY_DNS || '',
  SENTRY_AUTH_TOKEN: import.meta.env.VITE_SENTRY_AUTH_TOKEN || '',
  SENTRY_ORG: import.meta.env.VITE_SENTRY_ORG || ''
  // SENTRY_PROJECT: import.meta.env.PROJECT_NAME,
})
