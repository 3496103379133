import type { App } from 'vue'
import type { Router } from 'vue-router'
import * as Sentry from '@sentry/vue'
import { env } from '@/shared/config'

export function sentryPlugin(app: App, router: Router) {
  const isProd = env.MODE === 'production'

  // https://docs.sentry.io/platforms/javascript/guides/vue/configuration/options/
  Sentry.init({
    app,
    enabled: isProd,
    dsn: env.SENTRY_DNS,
    debug: false,
    release: env.PROJECT_NAME + '@' + env.VERSION,
    environment: env.MODE,
    sampleRate: isProd ? 0.01 : 1,
    maxBreadcrumbs: 300,
    attachStacktrace: true,
    transport: Sentry.makeBrowserOfflineTransport(Sentry.makeFetchTransport),
    integrations: [
      Sentry.browserTracingIntegration({
        router
      }),
      Sentry.replayIntegration()
    ],
    tracePropagationTargets: ['localhost', env.HOST_NAME],
    tracesSampleRate: isProd ? 0.01 : 1,
    replaysSessionSampleRate: isProd ? 0.01 : 1,
    replaysOnErrorSampleRate: 1.0,
    hooks: [], // ['mount', 'update'],
    timeout: 2000,
    trackComponents: false
  })
}
