export const ROUTE_NAV = {
  index: '/',
  error: '/error/',
  cabBundles: '/cab/bundles/',
  cabHistory: '/cab/history/',
  cabRefund: '/cab/refund/',
  cabReport: '/cab/report/',
  cabPayment: '/cab/payment/',
  cabPayment3ds: '/cab/payment/3ds/',
  cabPaymentError: '/cab/payment/error/',
  cabPaymentSuccess: '/cab/payment/success/'
}
