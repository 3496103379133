import { v4 as uuidv4 } from 'uuid'
import { cookies } from '@/shared/lib/cookies'

const FIELD_NAME = 'deviceId'

export function deviceIdGet(): string {
  let deviceId = cookies.get(FIELD_NAME) || localStorage.getItem(FIELD_NAME)
  if (!deviceId) {
    deviceId = uuidv4()
  }
  localStorage.setItem(FIELD_NAME, deviceId)
  cookies.set(FIELD_NAME, deviceId, 365 * 3)

  return deviceId
}
